/* App.css */
@import '~font-awesome/css/font-awesome.min.css';


:root {
  --background: white;
  --text-primary: black;
  --text-secondary: royalblue;
  --accent: purple;
  color: #000000;
}

[data-theme='dark'] {
  --background: black;
  --text-primary: white;
  --text-secondary: grey;
  --accent: darkred;
  color: #ffffff;
}

.prueba {
  background-color: var(--background);
  color: var(--text-primary);
}

.App {
  color: var(--text-primary);
  background-color: var(--background);
  font-size: large;
  font-weight: bold;
  padding: 0px;
  /*height: calc(100vh - 40px);*/
  transition: all .5s;
}

button {
  color: var(--text-primary);
  background-color: var(--background);
  border: 2px var(--text-primary) solid;
  float: right;
  transition: all .5s;
}

.App-header {
  background-color: #eeeeee00;
  /*color: #ff0000;*/
  min-height: 82.2vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  padding-top: 2vh;
}

/*.App {
  text-align: center;
  overflow-x: hidden; /* Hide horizontal scrollbar */
/*}*/

/*.App-logo {
  height: 30vmin;
  pointer-events: none;
}*/

/*@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 1s;
  }
}*/

/*.App-link {
  color: #61dafb;
}*/

/*@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(72000deg);
  }
}

@-webkit-keyframes updown-slide {
  0% {
    left: 0;
    top: 0;
  }
  100% {
    left: 0px;
    top: 20px;
  }
}

@keyframes updown-slide {
  0% {
    left: 0;
    top: 0;
  }
  100% {
    left: 0px;
    top: 20px;
  }
}

#slide {
  -webkit-animation: updown-slide 3s;
  animation: updown-slide infinite 1s alternate;
  width: 20%;
  height:20%;
  position: relative;
}*/

@media (max-width: 767px) {

  /* Align text to center.*/
  .text-sm-center {
    text-align: center;
  }

  .obj-sm-center {
    color: red;
    display: flex;
    justify-content: center;
  }

  .obj-sm-disapear {
    display: none;
  }
}

@media (min-width: 576px) {
  .cancelar-max-width {
    max-width: 100% !important;
  }
}

.GutterX0 {
  --bs-gutter-x: 0rem !important;
}

/*---------------------------------------------------------------------*/

/* styles.css */
.file {
  /* Estilos comunes para el componente, puedes ajustar según tus necesidades */
  color: var(--text-primary);
  font-size: 1rem;
  /* Tamaño de fuente por defecto para xs */
}

@media (min-width: 601px) {

  /* Font-size para sm */
  .file {
    font-size: 1.2rem;
  }
}

@media (min-width: 961px) {

  /* Font-size para md */
  .file {
    font-size: 2rem;
  }
}

@media (min-width: 1281px) {

  /* Font-size para lg */
  .file {
    font-size: 2rem;
  }
}

@media (min-width: 1921px) {

  /* Font-size para xl */
  .file {
    font-size: 2rem;
  }
}


