.drawer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    width: 100vw;
    height: 7.5vh;
    background-color: blue;
    z-index: 1000;
  }
  
  .drawer-content {
    display: none;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
  }
  
  .drawer-content.open {
    display: block;
    height: 100px; /* Ajusta la altura según tus necesidades */
  }
  
  /* Estilos específicos para la versión móvil */
  .drawer-container.mobile button {
    display: block;
  }
  
  .drawer-container.pc button {
    display: none;
  }
  
  .NavLetra {
    color:var(--text-primary);
    transition: "background-color 0.5s ease";
  }

  .NavLetra:hover {
    color: var(--background);
    transition: "background-color 0.5s ease";
  }